import React, { Component } from 'react'
import PropTypes from 'prop-types';
import './styles.css'
import gtm from "../../usagetracker/GTM";
import ProductConfig from "./config.json";
import actions from '../../auth/actionCreator'
import Insight from '../../component/InsightSection';
import { TalentNeuronLogo, Loader, FeatureAnnouncement, Icon, getPlanModuleDetails, VideoModal, UniversalSearchV2, NewProductIds } from 'tn-components-library';
import Accordion from '../../component/Accordion';
import BannerAccordion from '../../component/BannerAccordion';
import AnnouncementPrompt from "../../component/AnnouncementPrompt";
import ProductCard from '../../component/ProductCard/index'
import Announcements from "../../http/Announcements";
import infotooltipAPI from '../../http/infotooltip';
import ModuleCard from '../../component/ModuleCard';
import { MODULARIZATION_WALKTHROUGH_VIDEO_URL, getModuleNames, getRolesFromProductDetails } from '../../utils';
import http from '../../http';

const onWidgetClick = (WidgetDetails) => {
  Announcements.recentSearchTracking(WidgetDetails)
}

class Home extends Component {
  constructor(props, context) {
    super(props, context);


    this.state = {isOpen: true,
        newAccess: true,
        MAData:null,
        isMADataLoading:false,
        hasPermission:false,
        showAnnouncementWarning:false,
        featureAnnouncement: null,
        isFeatureEnabled: false,
        openModularizationWalkthroughModal: false,
        insights:null,
        recentSearches:[],
        topInsights: []
    }
    gtm.init(window.Gemini.UserData);
    this.trackUsage = this.trackUsage.bind(this);
    this.openURL = this.openURL.bind(this);
    this.ProductList = (window.Gemini && window.Gemini.UserData) ? this.getProductList() : [];
    this.showOrHideBanner = this.showOrHideBanner.bind(this);
    this.closePromptHandler = this.closePromptHandler.bind(this);
    this.trackTNDataConnections = this.trackTNDataConnections.bind(this);
    this.closeAnnouncement = this.closeAnnouncement.bind(this);
    this.moduleList = (window.Gemini && window.Gemini.UserData) ? this.getModuleList() : []
    this.alertsEnabled = window.Gemini && window.Gemini.UserData && window.Gemini.UserData.alertsEnabled
    this.handleTracking = this.handleTracking.bind(this);
    this.args = this.args.bind(this);
    this.handleShowOnBoarding = this.handleShowOnBoarding.bind(this);
    this.handleCloseOnBoarding = this.handleCloseOnBoarding.bind(this);
    this.updateTooltipInfo = this.updateTooltipInfo.bind(this);
    this.getNotificationAnnouncements = this.getNotificationAnnouncements.bind(this);
  }

  args() {
    if (window.Gemini.UserData) {
      return {
        roles: getRolesFromProductDetails(window.Gemini.UserData?.productDetails),
        planAccess: window.Gemini.UserData.PLAN2x,
        recruitAccess: window.Gemini.UserData.GEMINI,
        customRoleAccess: window.Gemini.UserData.customRoleEnabled,
        dataFeedAccess: window.Gemini.UserData.dataFeedEnabled,
        activeProduct: NewProductIds.HOME,
        urls: {
          plan: `${window.Gemini.Config.plan2xURL}#`,
          insight: `${window.Gemini.Config.homeURL}/learnmore/insights`,
          recruit: `${window.Gemini.Config.recuritURL}#`,
          home: window.Gemini.Config.homeURL,
          customrole: window.Gemini.Config.customRoleURL,
          datafeeds: window.Gemini.Config.dataFeedURL,
          customSolution: `${window.Gemini.Config.homeURL}/customsolutions`,
        },
        onClickCallback: onWidgetClick
      }
    }
  }

  getModuleList () {
    const userData = window.Gemini.UserData;
    let moduleNames = []
    const roles = getRolesFromProductDetails(window.Gemini.UserData?.productDetails)
    const moduleList = getPlanModuleDetails(roles, userData['PLAN2x'])
    moduleNames = getModuleNames(moduleList)
    userData['GEMINI'] && moduleNames.push('recruit') 
    return moduleNames
  }

  getProductList() {
    let list = [], appPerms = window.Gemini.UserData;    
    appPerms['customRoleEnabled'] && list.push('customRoleEnabled')
    list.push('talentIntelligenceApisEnabled')
    appPerms['dataFeedEnabled'] && list.push('dataFeedEnabled')
    return list;
  }

  trackUsage(urlKey) {
    let url = ''
    switch (urlKey) {
      case 'tnApiURL':
        url = `${window.Gemini.Config.homeURL}/home/requestapiaccess`
        gtm.track(gtm.events.linkClick, {
          action: 'Go to Talent Intelligence API clicked',
          category: 'API documentation page load initiated',
          label: window.Gemini.UserData.userEmail,
          page: 'Homepage'
        });
        break;
      case 'homeURL':
        url = window.Gemini.Config[urlKey];
        url = `${url}/learnmore/insights`
        gtm.track(gtm.events.linkClick, { page: 'TN Homepage', category: 'Insights', action: 'Insights opened' });
        break;
      case 'customRoleURL':
        url = window.Gemini.Config[urlKey];
        gtm.track(gtm.events.linkClick, { page: 'TN Homepage', category: 'Custom Roles', action: 'Module opened' });
        break;
      case 'dataFeedURL':
        url = window.Gemini.Config[urlKey];
        gtm.track(gtm.events.linkClick, { page: 'TN Homepage', category: 'Data Feeds', action: 'Module opened' });
        break;
    }
    setTimeout(()=>{
      window.open(url, '_self');
    }, 200)
  }

  getfilteredAnnouncements = (announcements) => {
    const majorAnnouncements = []
    announcements.forEach(announcement => {
      majorAnnouncements.push(announcement)
    })  
    return { majorAnnouncements }
  }

  getMajorAnnouncementData() {
    const productPermission = {
      Recruit: window.Gemini.UserData.GEMINI,
      Plan: window.Gemini.UserData.PLAN2x,
      Custom_Roles : window.Gemini.UserData.customRoleEnabled,
      DataFeed: window.Gemini.UserData.dataFeedEnabled,
      Admin: window.Gemini.UserData.adminEnabled,
      Datafeeds: window.Gemini.UserData.dataFeedEnabled
    };
    const date = new Date();
    const currentDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    Announcements.getAnnouncementByDate(currentDate)
      .then(responses => {
        const data = responses.data;
        if (data.majorAnnouncements.length > 0 && !responses.isError) {
          const { majorAnnouncements } = this.getfilteredAnnouncements(data.majorAnnouncements)
          if (majorAnnouncements.length > 0) {
            const tag = majorAnnouncements[0]?.platformtag?.replace(/\s+/g, ' ')?.trim()?.replace(" ", "_")
            this.setState({
              isOpen: majorAnnouncements?.[0]?.userpreference,
              MAData: majorAnnouncements?.[0],
              hasPermission: tag && productPermission[tag]
            }, () => {
              const announcementId = this.state.MAData?.id;
              if (announcementId) {
                Announcements.setMajorAnnouncementUserPreferences(announcementId, false)
              }
            })
          }
        }
      })
  }

  getNotificationAnnouncements (notificationResponse, productPermission) {
    const notificationsData = notificationResponse?.data?.notifications || []
    if (!notificationResponse?.isError && notificationsData?.length) {
      const notifications = []
      const announcements = notificationsData.sort((prev, next) => (prev.id > next.id ? -1 : 1))
      if (announcements.length) {
        let tag = announcements[0].platformtag
        if (tag) {
          tag = tag.replace(/\s+/g, ' ')?.trim()?.replace(' ', '_')
        }
        if (tag === null || productPermission[tag]) {
          notifications.push(announcements[0]);
        }
        this.setState({
          isFeatureEnabled: notifications.length > 0,
          featureAnnouncement: notifications?.[0] || null
        })
      } else {
        this.getMajorAnnouncementData();
      }
    } else {
      this.getMajorAnnouncementData();
    }
  }

  async componentDidMount() {
    const productPermission = {
      Recruit: window.Gemini.UserData.GEMINI,
      Plan: window.Gemini.UserData.PLAN2x,
      Custom_Roles : window.Gemini.UserData.customRoleEnabled,
      DataFeed: window.Gemini.UserData.dataFeedEnabled,
      Admin: window.Gemini.UserData.adminEnabled,
      Datafeeds: window.Gemini.UserData.dataFeedEnabled
    };
    const date = new Date();
    let currentDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    this.setState({
      isMADataLoading: true
    });
    let that = this;
    let insightsOptions =  {
      categories: 'All',
      pageno: 1,
      totalrecords: 100,
      fromdate: '2020-01-01',
      todate: '2099-12-31',
      searchtext: '',
    }
    const insightURL = window.Gemini.Config.adminpublicserviceURL + '/v1/cms/gettninsights'
    const recentSearchUrl = window.Gemini.Config.adminpublicserviceURL + '/tnrecentsearch/'
    try {
      try {
        const responses = await Promise.all([Announcements.getNotificationByDate(currentDate), infotooltipAPI.getInfoTooltip(), http.post(insightURL, insightsOptions), http.get(recentSearchUrl)]);
        const notificationResponse = responses[0];
        const tooltipInfo = responses[1];
        const insightResponse = responses[2].data;
        const recentSearchResponse = responses[3].data;
        that.getNotificationAnnouncements(notificationResponse, productPermission);
        if (!tooltipInfo?.isError) {
          const tooltipInfoList = tooltipInfo.infotooltip || [];
          that.context.store.dispatch(actions.setInfoTooltipData(tooltipInfo.infotooltip));
          const walkthroughSetting = tooltipInfoList.find(el => el.name === 'platform_walkthrough_video')
          const isShowOnBoardingEnabled = walkthroughSetting && !walkthroughSetting?.isEnabled
          if(isShowOnBoardingEnabled){
            document.body.classList.add('modal-open')
          }
          that.setState({
            openModularizationWalkthroughModal: isShowOnBoardingEnabled
          });
        }
        if (!insightResponse.isError && insightResponse.data && insightResponse.data.insightslist?.length) {
          const insightList = insightResponse.data.insightslist || [];
          const insights = insightList.slice(0, 3);
          that.setState({
            insights: insightList,
            topInsights: insights
          });
        }
        if (!recentSearchResponse.isError && recentSearchResponse.data && recentSearchResponse.data.tnrecentsearch) {
          const recentSearches = recentSearchResponse.data.tnrecentsearch || [];
          that.setState({
            recentSearches: recentSearches
          });
        }
      } catch (error) {
        console.log({ error });
      }
    } finally {
      this.setState({
        isMADataLoading: false
      });
    }
  }
  

  openURL(urlKey) {
    this.trackUsage(urlKey);
  }

  showOrHideBanner(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
    !this.state.isOpen ?
      gtm.track(gtm.events.viewed, { category: 'Legacy Home Page', action: 'Advertisement Show Clicked' })
      :
      gtm.track(gtm.events.viewed, { category: 'Legacy Home Page', action: 'Advertisement Hide Clicked' });

  }

  closeAnnouncement(e) {
    this.showOrHideBanner(e);

  }



  closePromptHandler(){
    this.setState({
      showAnnouncementWarning: false
    })
  }
  redirectToLink = (link) => {
    gtm.track(gtm.events.linkClick, { page: 'Log In Experience' , category: 'Announcement'});
    if (this.state.hasPermission) {
      window.open(link, '_blank')
    }else{
      this.setState({
        showAnnouncementWarning: true
      })
    }
  }

  trackTNDataConnections(){
    gtm.track(gtm.events.linkClick, {
    action: 'Marketing label CTA clicked',
    category: 'Platform training page load initiated',
    label: window.Gemini.UserData.userEmail,
    page: 'Homepage'
    }); 
    return true
  }

  handleTracking(title) {
    gtm.track(gtm.events.linkClick, { category: title, action: 'Link clicked', page: 'TN Homepage' })
  }

  handleShowOnBoarding() {
    const tooltipInfoList = this.context.store.getState()?.tooltipInfo || []
    const walkthroughSetting = tooltipInfoList.find(el => el.name === 'platform_walkthrough_video')
    const isShowOnBoardingEnabled = walkthroughSetting && !walkthroughSetting?.isEnabled
    this.setState({
      openModularizationWalkthroughModal: isShowOnBoardingEnabled
    })
  }

  handleCloseOnBoarding() {
    this.setState({ openModularizationWalkthroughModal: false })
    this.updateTooltipInfo('platform_walkthrough_video', 'ToolTip')
    document.body.classList.remove('modal-open')
  }

  updateTooltipInfo(featureName, groupName) {
    const tooltipInfoList = (this.context.store.getState().tooltipInfo) || []
    gtm.track(gtm.events.click,
      {
        category: 'Modularization Walk through Video',
        action: '"Close" CTA clicked'
      })
    tooltipInfoList.forEach(x => {
      if (x.name === featureName) {
        x.isEnabled = true
      }
    })
    this.context.store.dispatch(actions.setInfoTooltipData(tooltipInfoList))
    infotooltipAPI.setInfoToolAsViewed({ featurename: featureName, groupname: groupName })
  }

  render() {
    let { isOpen, featureAnnouncement, isFeatureEnabled } = this.state;
    let messages = this.context.intl;
    let finalPropsforSearch = { ...this.args(), insights: this.state.insights, recentSearches: this.state.recentSearches }
    return (
      <div className="home-page">
        {
          isFeatureEnabled && featureAnnouncement && 
            <FeatureAnnouncement
              id={featureAnnouncement.id}
              title={featureAnnouncement.title}
              message={featureAnnouncement.message}
              ctaUrl={featureAnnouncement.ctaurl}
              endDate={featureAnnouncement.enddate}
              collapseOnScroll={false}
            />
        }
        <div className={`${!isOpen  ? "accordion-closed" : (this.state.showAnnouncementWarning ? "show-announcement" : "accordion-closed") }`}>
       
          {this.state.MAData && !isFeatureEnabled &&
          <BannerAccordion
              isOpen={this.state.isOpen}
              showOrHideBanner={this.showOrHideBanner}
              redirectTo={this.redirectToLink}
              {...this.state.MAData}
          />
          }
          { this.state.showAnnouncementWarning && <AnnouncementPrompt  closeHandler={this.closePromptHandler} {...this.state.MAData} />}

          <div className="homepage-cotainer">
          <div className='module-block'>
            {this.state.isMADataLoading ?
                <Loader type='light' /> :
                <div>
                  {!isFeatureEnabled && this.state.MAData && <div onClick={this.showOrHideBanner} className="cursor">
                    <Accordion
                        isOpen={this.state.isOpen}
                        isMADataLoading={this.state.isMADataLoading}
                        toggleAccordion={this.showOrHideBanner}
                        {...this.state.MAData}/>
                  </div>}
                </div>
            }
            <div className="main-container padding-left-65 padding-right-0 content-section">
              <div className="row">
                <div className="productBlock">
                  <div className='tn-logo'>
                    <TalentNeuronLogo width='300px' height='42px' />
                  </div>
                    <div className="searchsection">
                      <div className='searchInput'>
                        <UniversalSearchV2 {...finalPropsforSearch}></UniversalSearchV2>
                      </div>
                    </div>
                  <div className="labelsection">
                    
                    <div className="label-container">
                      <div className='first-label'>
                      <div className='new-tag'>NEW</div>
                        <p className="laberHeader"><span className='header'>{messages.formatMessage({ id: 'talentneuron_training' })}</span></p>
                      </div>
                      <div className='second-label'>
                        <p className="laberHeader">
                          <span className="paragraph">{messages.formatMessage({ id: 'sign_up_for_training' })} <a href={'https://talentneuron.registration.goldcast.io/series/82c4ab92-9af9-4611-b806-82f492adb6c6'} target={'_blank'} onClick={this.trackTNDataConnections} >{messages.formatMessage({ id: 'register_here' })}</a>.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='module-section'>
                  <ModuleCard appList={this.moduleList} messages={messages}/> 
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='main-container padding-left-65 padding-right-0 content-section'>
                {finalPropsforSearch.planAccess &&
                  <>
                  <div className='project-section'>
                    <div className='project-desc'>{messages.formatMessage({ id: 'plan_projects_desc' })}</div>
                    <div className='find-project'>{messages.formatMessage({ id: 'find_your_project_here' })}</div>
                    <div className='project-alerts'>
                      <a className='my-projects' onClick={() => this.handleTracking('Your Projects')} href={`${window.Gemini.Config.plan2xURL}/#/projects`} target={'_self'} ><Icon name='my-projects'/>{messages.formatMessage({ id: 'your_projects' })}</a>
                      {this.alertsEnabled && <a className='alerts' onClick={() => this.handleTracking(messages.formatMessage({ id: 'alerts' }))} href={`${window.Gemini.Config.plan2xURL}/#/alerts`} target={'_self'} ><Icon name='email-alerts-bold'/>{messages.formatMessage({ id: 'alerts' })}</a>}
                    </div>
                  </div>
                  <hr className='horizontal-row'/>
                  </>
                }
                  {this.ProductList.length > 0 && <ProductCard 
                    productdetail={ProductConfig}
                    openURL={this.openURL}
                    productList={this.ProductList}
                  /> } 
                  <Insight product={ProductConfig.insights} openURL={this.openURL} insights={this.state.topInsights} />
          </div>
          <VideoModal
            open={this.state.openModularizationWalkthroughModal}
            setOpen={this.handleShowOnBoarding}
            url={MODULARIZATION_WALKTHROUGH_VIDEO_URL}
            onClose={this.handleCloseOnBoarding}
          />
        </div>
      </div>
    )
  }
}

Home.contextTypes = {
  intl: PropTypes.object.isRequired,
  store: PropTypes.object
}

export default Home;
